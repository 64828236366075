@import 'variables';

.navbar {
  width: 100%;
  height: 90px;
  position: fixed;
  background: #DDD;
  z-index: 3000;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);

  .navbar-navigation {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 50px;

    .navbar-logo {
      font-size: 2em;
      font-weight: 700;
      .teracode-red-title {
        color: $teraCodeRed;
      }

      .teracode-black-title {
        color: $teraCodeBlack;
      }

      display: flex;
      text-decoration: none;
    }

    .spacer {
      flex: 1;
    }

    .navbar-items {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;

        li {
          margin: 0px 30px;
        }
      }

      .item {
        color: $teraCodeBlack;
        text-decoration: none;
        font-size: 1em;

        &:hover,
        &:active {
          color: $teraCodeRed;
        }
      }

      .itemSelected {
         color: $teraCodeRed;
         font-weight: 700;
      }

      ul.submenu {
         display: block;
         background: $subMenuBackground;
         padding: 7px;
         position: absolute;
         -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
         -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
         box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
         border-bottom-left-radius: 2px;
         border-bottom-right-radius: 2px;
      }
      ul.submenu li {
           margin: 5px 10px;
        }
    }
  }
}