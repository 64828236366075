// Colours
$darkGrey: #5d6063;
$lightGrey: #eaedf0;
$inputBorder: #bfbfbf;
$bodyBackground: #f4f4f4;
$subMenuBackground: #dddddd;
$inputBorderFocus: #66afe9;
$inputBorderAutofill: #cccccc;

$teraCodeRed: #b0312e;
$teraCodeGrey: #808483;
$teraCodeBlack: black;
$teraCodeWhite: white;
