@import 'variables';
@import 'mixins';

.login-container {
  text-align: center;
  width: 40%;
  margin: 4% auto;
  padding: 50px;

  h1 {
    color: $teraCodeBlack;
    font-size: 30px;
    margin-bottom: 4%;
  }
} // end .login-containter

.login-form {
  background-color: #f6f7f8;
  border: 1px solid #d6d9dc;
  border-radius: 3px;

  width: 100%;
  padding: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.2);
} // end .login-form

.buttonProgress {
  color: #b0312e;
  position: relative;
  margin: auto;
}

.error-text {
    color: $teraCodeWhite;
    background: $teraCodeRed;
    width: 100%;
    padding: 5px;
}
@media only screen and (max-width: 600px) {
    .login-container {
        width: 100%;
        padding: 15px;
    }
    .login-form {
        padding: 10px;
    }
}