@import 'variables';
@import 'mixins';

.simplelist-container {
  @include container;

  .simplelist-title {
    @include title;
  }

  .simplelist-list {
    @include list;

    .simplelist-card {
      @include card;

      .card-title-actions {
        text-align: right;
        cursor: pointer;
        width: 50%;
      }
    }
  }
}
