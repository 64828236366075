@import "reset";
@import "variables";
@import "mixins";
@import url("https://fonts.googleapis.com/css?family=Bai+Jamjuree%3A200%2C300%2C400%2C500%2C600%2C700&#038;ver=1.0.0");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
html {
  box-sizing: border-box;
}

body {
  color: $darkGrey;
  line-height: 1.3;
  font-family: "Bai Jamjuree";
  font-size: 16px;
  background-color: $bodyBackground;
}

a,
a:hover,
a:active,
a:focus,
img {
  outline: 0;
}

a:hover,
a:active,
a:focus,
i {
  color: $teraCodeRed;
}

.main-container {
  align-items: center;
  justify-content: center;
  float: left;
  width: 100%;
  margin: 90px 0 0 0;
}

.input-field {
  @include input;
}

// end .input-field

.input-field [type="text"],
.input-field [type="password"],
.input-field [type="email"] {
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  border: 1px solid $inputBorder;
  resize: none;
  padding: 0 10px;
  font-size: 14px;
}

.input-field textarea {
  width: 100%;
  min-height: 240px;
  border-radius: 5px;
  border: 1px solid $teraCodeRed;
  resize: none;
  padding: 0 10px;
  font-size: 14px;
}

.input-field {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.MuiTableCell-root {
  font-family: "Bai Jamjuree" !important;
}

.MuiFormControl-root {
  padding: 20px !important;
  width: 100% !important;
}

.MuiInputLabel-outlined {
  transform: unset !important;
}

.MuiTableCell-head {
  font-size: 1.4rem !important;
}

.MuiToolbar-gutters {
  background-color: #444;
  color: #fff;
}

.MuiTablePagination-toolbar {
  background-color: #999;
}

.MuiDialogTitle-root {
  padding: 15px;
  background: $teraCodeRed;

  h2 {
    font-size: 1.4rem;
    color: #fff;
    font-weight: 800;
  }
}

.MuiToolbar-gutters .MuiInput-formControl {
  color: #fff;
}

.MuiToolbar-gutters .MuiInput-formControl input {
  color: #fff;
}

.MuiTableCell-alignRight.actions {
  padding: 0px 5px;
}

.header > .MuiFormControl-root .MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter.success div {
  background-color: #43a047;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter.warning div {
  background-color: #ffa000;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter.error div {
  background-color: #d32f2f;
}

.MuiButtonBase-root.btn-accept {
  background-color: #68aa13;
  color: #fff;
  margin: 5px;
}

.MuiButtonBase-root.btn-accept {
  background-color: #68aa13;
  color: #fff;
  margin: 5px;
}

.MuiButton-root.Mui-disabled.btn-accept {
  background-color: #ddd;
}

.MuiButtonBase-root.btn-accept:hover {
  color: #000;
}

.MuiButtonBase-root.btn-cancel {
  background-color: #ddd;
  color: #000;
  margin: 5px;
}

.question-body > .MuiFormControl-root {
  width: 100% !important;
}

.question-body > .MuiFormControl-root .MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}

.MuiFormControl-fullWidth {
  width: 100% !important;
}

#menu-Language {
  z-index: 4000 !important;
}

.MuiButton-root.save {
  color: rgba(255, 255, 255, 0.87) !important;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Bai Jamjuree";
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #b0312e !important;
  min-width: 151px !important;
}

.navbar-items .MuiFormControl-root {
  padding: 0 !important;
}

[data-react-beautiful-dnd-droppable="0"] {
  overflow-anchor: none;
  width: 100% !important;
  box-sizing: border-box;
}

[data-react-beautiful-dnd-drag-handle="0"] {
  cursor: -webkit-grab;
  cursor: grab;
  width: 100%;
  box-sizing: border-box;
  background: #fff !important;
  overflow: hidden;
}

.question-number {
  width: auto;
  float: left;
  margin: 0 0 15px 0;
}

.question-number-rounded {
  border-radius: 50%;
  font-size: 1.3rem;
  background-color: #b0312e;
  color: #fff;
  float: left;
  padding: 7px;
  font-weight: 700;
  width: 45px;
}

.section-number-rounded {
  border-radius: 10%;
  font-size: 1.3rem;
  color: #777;
  float: left;
  padding: 7px;
  font-weight: 700;
  width: 45px;
  border: 1px solid #ccc;
  display: none;
}

.question-remove-rounded {
  border-radius: 50%;
  font-size: 1rem;
  background-color: #ddd;
  color: #fff;
  float: right;
  padding: 7px;
  font-weight: 700;
  width: 35px;
  cursor: pointer;
}

.question {
  width: 90%;
  float: left;
  padding: 20px 25px 0 25px;
  text-align: left;
  font-size: 1rem;
  font-weight: 700;
}

.question .MuiTypography-subtitle1 {
  width: 80%;
  float: left;
}

.question .question-type {
  float: right;
  font-size: 0.95rem;
  font-weight: 300;
}

.question .question-content {
  width: 100%;
}

.question .question-content .attach-ro-footer {
  font-size: 0.8rem;
  font-weight: 300;
}

.question .question-content .MuiTypography-h6 {
  font-size: 1rem;
}

.save-button {
  color: white !important;
  margin: 10px !important;
  background-color: $teraCodeRed !important;
}

.cancel-button {
  color: white !important;
  margin: 10px !important;
  background-color: $teraCodeGrey !important;
}

.button-footer {
  margin: 0 !important;
  /*
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #DDD;
  width: 100%;
  z-index: 3000;
  */
}

.cliente-data {
  text-align: left;
  padding: 20px;
}
.makeStyles-root-231 {
  display: block !important;
}

.material-icons.add-question {
  font-size: 2.1rem;
  cursor: pointer;
  color: #b0312e;
}

.material-icons.added-question {
  font-size: 2.1rem;
  color: #4caf50;
}

.material-icons.info-question {
  font-size: 2.1rem;
  cursor: pointer;
  color: #444;
}

.material-icons.edit-question {
  font-size: 1.5rem;
  cursor: pointer;
  color: #444;
}

.material-icons.edit-question.disabled {
  color: #ccc;
}

[data-react-beautiful-dnd-droppable] {
  overflow-anchor: none;
  width: 100% !important;
  box-sizing: border-box;
}

[data-react-beautiful-dnd-drag-handle] {
  cursor: grab;
  width: 100%;
  box-sizing: border-box;
  background: #fff !important;
  overflow: hidden;
}

.multiple-choice-container .ReactTags__selected {
  width: 90%;
}

.multiple-choice-container .ReactTags__selected .ReactTags__tag:before {
  background: url(/option.svg);
  content: ".";
  color: #fff;
  padding: 0px 8px;
  background-size: contain;
  margin: 0 8px;
}

.multiple-choice-container .ReactTags__selected .ReactTags__tag {
  width: 100%;
  margin: 4px 0;
  background: none;
}

.multiple-choice-container .ReactTags__selected a.ReactTags__remove {
  float: right;
}

.filepond--item {
  width: 20%;
  float: left;
}

.compound-origin {
  width: 100%;
  background-color: whitesmoke;
  margin: 10px 0;
  float: left;
}

.compound-result {
  width: 100%;
  background-color: whitesmoke;
  margin: 10px 0;
  float: left;
  min-height: 200px;
  text-align: center;
}

.drop_msg {
  position: relative;
  margin: 20px;
}

.drop_msg span {
  padding: 40px;
  font-size: 1.1rem;
  color: #d32f2f;
  width: 50%;
  font-weight: 700;
}

.MuiFormControl-root .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  position: relative;
  text-align: left;
  padding: 0 20px;
}

.compound-origin .compound-origin-item {
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  user-select: none;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 6px 2px;
  width: 55px;
  height: 55px;
  font-size: 18px;
  margin: 6px 20px;
  border-radius: 8px;
  float: left;
}

.compound-origin .compound-origin-item img {
  width: 70%;
}

.compound-result .compound-result-item {
  margin: 2%;
  width: 95%;
}

.compound-result
  .compound-result-item
  .multiple-choice-container
  .tag-wrapper.ReactTags__tag {
  width: 100%;
}

.compound-result .compound-result-item label span.compound-title {
  font-size: 1.3rem;
  padding: 1% 0.5%;
  float: left;
}

.compound-result .compound-result-item label img {
  float: left;
  margin: 0;
}

.reviews .MuiInputBase-inputMultiline {
  border: none;
  overflow-y: auto !important;
}

.reviews .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $teraCodeRed !important;
}

.reviews .MuiTextField-root {
  padding-bottom: 10px !important;
}

.reviews .MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 0.8rem;
  top: 5px;
  left: -3px;
}

.reviews legend {
  width: 140px !important;
}

.reviews .MuiFormLabel-root.Mui-focused {
  color: $teraCodeRed !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: $teraCodeRed !important;
}

.btn-container {
  display: inline-flex;
}

.simplelist-container .btn-container .MuiFormControlLabel-labelPlacementStart {
  flex-direction: row-reverse;
}

.btn-switch {
  margin-right: 20px;
}

.simplelist-container-text-left {
  text-align: left !important;
}

.simplelist-container .MuiFormControlLabel-labelPlacementStart {
  flex-direction: initial;
}

.exam-compound-question {
  width: 100%;
  min-height: 100px;
  float: left;
}

.exam-compound-question .MuiFormControlLabel-labelPlacementStart {
  flex-direction: unset;
}

.exam-compound-question .MuiTypography-h6 {
  font-weight: 300;
  font-size: 1rem;
}

.attach-ro-container {
  width: 250px;
  float: left;
  padding: 7px;
  margin: 7px;
}

.attach-ro-image {
  width: 250px;
  float: left;
  padding: 7px;
  margin: 7px;
  border: 1px dashed #ccc;
}

.attach-ro-doc {
  width: 250px;
  float: left;
  padding: 7px;
  margin: 7px;
  border: 1px dashed #ccc;
  text-align: center;
}

.attach-ro-footer {
  width: 250px;
  padding: 7px;
  margin: 7px;
}

.attach-ro-doc svg {
  color: #444;
}

.infoModal {
  padding: 10px !important;
}

.infoModal .ReactTags__selected {
  padding: 5px;
}

.exam-actions {
  display: flex !important;
  place-content: center;
}

.filepond--list .filepond--item {
  height: unset !important;
  min-height: 300px !important;
}

.MuiDivider-middle {
  margin: 16px;
  width: 100%;
  display: flex;
  height: 2px;
}

.exam-compound-question {
  position: relative;
}

.exam-compound-question ul li {
  padding: 0;
}

.exam-compound-question .MuiListItemAvatar-root .MuiAvatar-root img,
.reviewQuestion .MuiListItemAvatar-root .MuiAvatar-root img {
  width: 60%;
}

.exam-answer {
  display: grid;
  position: relative;
}

.reviewQuestion {
  min-height: 100px;
  position: relative;
}

.exam-answer .MuiInputBase-inputMultiline {
  background-color: #fff;
}

.exam-compound-question .MuiListItemAvatar-root,
.exam-answer .MuiListItemAvatar-root,
.reviewQuestion .MuiListItemAvatar-root,
.reviewAnswer .MuiListItemAvatar-root {
  width: auto;
  float: left;
  position: absolute;
  top: 30px;
  left: 30px;
}

.exam-compound-question .MuiListItemAvatar-root .MuiAvatar-root,
.exam-answer .MuiListItemAvatar-root .MuiAvatar-root,
.reviewQuestion .MuiListItemAvatar-root .MuiAvatar-root,
reviewAnswer .MuiListItemAvatar-root .MuiAvatar-root {
  width: 54px;
  height: 54px;
}
.exam-answer .attach-ro-container,
.reviewAnswer .attach-ro-container {
  width: 100%;
}
.exam-answer main {
  float: right;
  width: 90%;
  margin: 0 0 0 10%;
}
.exam-compound-question .MuiListItemAvatar-root .MuiAvatar-root.avatar-teracode,
.reviewQuestion .MuiListItemAvatar-root .MuiAvatar-root.avatar-teracode {
  width: 54px;
  height: 54px;
  border: solid 1px #ddd;
  border-radius: 3px;
  background: none;
}

.reviewQuestion .MuiTypography-h6 {
  font-size: 1rem;
  font-weight: 300;
}

.candidate-exam-compound-question {
  position: relative;
  width: 100%;
  float: left;
}

.candidate-exam-compound-question main {
  width: 90%;
  margin-left: auto;
  margin-right: unset;
}

.candidate-exam-compound-question .MuiFormControlLabel-labelPlacementStart {
  flex-direction: initial;
}

.exam-compound-answer {
  width: 100%;
  float: left;
  color: #999;
  background-color: #f9f9f9;
}

.exam-compound-answer .MuiGrid-container {
  width: 100%;
  float: left;
}

.exam-answer .filepond--wrapper {
  float: left;
  width: 100%;
}

.exam-answer .compound-origin {
  width: 90%;
  margin-left: auto;
  margin-right: unset;
}

.exam-answer .compound-result {
  width: 90%;
  margin-left: auto;
  margin-right: unset;
}

.exam-answer .MuiFormControlLabel-labelPlacementStart {
  flex-direction: initial;
}

.exam-footer {
  display: block;
  float: left;
  width: 100%;
  margin: 50px 0;
}

.review-container .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
  width: 100%;
  float: left;
}

.review-container
  .MuiListItem-root.makeStyles-cardExamItem-130.MuiListItem-gutters {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ddd;
  padding-bottom: 45px;
}

.review-container
  .MuiTypography-root.MuiTypography-h6.MuiTypography-gutterBottom {
  margin-top: 45px;
}

.review-container
  .MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom.MuiTypography-alignLeft {
  width: 100%;
  float: left;
  margin: 50px;
}

.review-container
  .MuiPaper-root.MuiPaper-elevation1.MuiExpansionPanel-root.MuiExpansionPanel-rounded.MuiPaper-rounded {
  width: 100%;
  float: left;
}

.exam-compound-answer {
  width: 100%;
  float: left;
  color: #999;
  background-color: #f9f9f9;
}

.MuiExpansionPanelSummary-content {
  align-items: center;
}

.multiple-choice-container .tag-wrapper.ReactTags__tag {
  width: max-content;
}

.reviewQuestion .MuiTypography-h6 {
  margin-top: 0 !important;
}

.reviewQuestion fieldset {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.reviewAnswer {
  width: 100%;
  float: left;
  color: #999;
  background-color: #f9f9f9;
  margin: 0 0 20px 0;
  position: relative;
  min-height: 100px;
  padding: 15px 0;
}

.reviewAnswer main ul li {
  margin: 0;
  padding: 0;
}

.reviewAnswer .MuiTypography-h6 {
  margin-top: 0 !important;
}

.answer-type-container .checkbox-container label {
  width: auto;
  float: left;
  clear: both;
  margin: 0 0 0 2%;
}

i.disabled {
  color: darkgrey !important;
}

.tag-body .MuiFormControl-root {
  padding-top: 0 !important;
}

.answer-type-container .MuiFormControl-root {
  padding-top: 0 !important;
}

.answer-type-container .Mui-focused {
  color: #5d6063 !important;
}

.compound-question-title {
  text-align: left;
  padding-left: 40px;
  color: #5d6063;
}
.component-bar {
  position: sticky;
  top: 90px;
  z-index: 3000;
  background-color: #fff;
  height: 110px;
}
.question-type-spacing span + span {
  margin-left: 5px;
}

.reviewComment .MuiFormControl-root {
  padding: 10px 20px !important;
}

.MuiRadio-colorPrimary.Mui-disabled {
  color: #5d6063 !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: #5d6063 !important;
}

.reviewComment
  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline {
  min-height: 20px !important;
  line-height: 20px !important;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.MuiInputBase-inputSelect {
  padding-top: 0 !important;
}

@media only screen and (max-width: 600px) {
  .login-container {
    width: 100%;
    padding: 15px;
  }
  .login-form {
    padding: 10px;
  }
  .simplelist-container {
    padding: 10px;
  }
  .MuiDialog-paper {
    margin: 15px;
  }
  header .MuiTypography-h5 {
    font-size: 0.8rem;
    width: 50%;
    position: relative;
    float: right;
    padding: 0;
    margin: 0;
  }
  main .MuiTypography-h2 {
    font-size: 2.75rem;
  }
  main .MuiTypography-h5 {
    font-size: 1.1rem;
  }
  main .MuiContainer-root main {
    width: 100%;
    margin: 0;
  }
  .exam-compound-question .MuiListItemAvatar-root,
  .exam-answer .MuiListItemAvatar-root,
  .reviewQuestion .MuiListItemAvatar-root,
  .reviewAnswer .MuiListItemAvatar-root {
    top: 7px;
    left: -40px;
  }
  .exam-compound-question
    .MuiListItemAvatar-root
    .MuiAvatar-root.avatar-teracode,
  .reviewQuestion .MuiListItemAvatar-root .MuiAvatar-root.avatar-teracode {
    width: 30px;
    height: 30px;
  }
  .reviewAnswer .MuiAvatar-root {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }
  .candidate-exam-question {
    padding: 0 !important;
    margin: 0 !important;
  }
  .exam-compound-question,
  .exam-answer,
  .reviewAnswer {
    position: relative;
    width: 90%;
    float: right;
  }
}
