@import 'variables';

@mixin container {
  text-align: center;
  width: 100%;
  padding: 50px;
}

@mixin title {
  border-bottom: 1px solid;
  color: $teraCodeRed;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  h3 {
    font-size: 2em;
    text-align: start;
    margin: 10px 40px;
  }
}

@mixin input {
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input {
    &:-webkit-autofill {
      border: 1px solid $inputBorderAutofill;
      box-shadow: inset 0 0 0px 9999px $teraCodeWhite;
    }

    &:focus,
    &:focus-visible,
    &:-webkit-autofill:focus {
      border-color: $inputBorderFocus;
      box-shadow: inset 0 0 0px 9999px $teraCodeWhite, 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  &:focus {
    outline: none;
  }

  label {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  [type='text'],
  [type='password'],
  [type='email'] {
    width: 200px;
    height: 25px;
    margin-top: 10px;

    border-width: 2px;
  }
}

@mixin list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin card {
  width: 90%;
  margin: 5px;
  padding: 20px;
  font-size: 14px;

  box-shadow: 0px 0px 5px 1px rgba(15, 15, 15, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .card-title {
    width: 50%;
  }

  p {
    margin-left: 30px;
    text-align: start;
  }
}
