@import 'variables';
@import 'mixins';

.candidates-container {
  @include container;

  .candidates-title {
    @include title;

  }

  .candidates-list {
    @include list;

    .candidate-card {
      @include card;
    }
  }
}
